import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DynamicDataServiceProxy, SaveDynamicDataRequest } from '@shared/service-proxies/service-proxies';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'dynamic-reset-password-button',
    templateUrl: './dynamic-reset-password-button.component.html',
    styleUrls: ['./dynamic-reset-password-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicResetPasswordButtonComponent extends AppComponentBase {

    @Input() actionButtonStructure: any;
    @Input() dynamicFormComponents: any;
    disablePasswordResetButton: boolean = false;
    renderingPasswordReset: boolean = false;

    constructor(
        injector: Injector,
        private cd: ChangeDetectorRef,
        private _dynamicDataServiceProxy: DynamicDataServiceProxy
    ) {
        super(injector);
    }

    resetPassword() {
        this.renderingPasswordReset = true;
        let request = new SaveDynamicDataRequest();
        request.dataSourceName = "ResetPassword";
        request.action = "update";
        request.data = {};

        if (this.dynamicFormComponents[0].formContents !== undefined) {
            request.data["userId"] = this.dynamicFormComponents[0].formContents.id;
        }

        request.data["subdomain"] = window.location.host.split('.')[0];

        this._dynamicDataServiceProxy.saveDynamicData(request)
            .pipe(
                finalize(() => {
                    this.renderingPasswordReset = false;
                    this.cd.markForCheck();
                })
            ).subscribe(result => {
                if (result.data.items[0].isSuccess) {
                    this.disablePasswordResetButton = true;

                    this.message.success(this.l("ResetSuccessfully"), this.l("PasswordReset"), { isHtml: true });
                }
                else {
                    let displayError = "";
                    result.data.items[0].validationMessage.forEach(error => {
                        displayError += error + "<br><br>";
                    });

                    this.message.error(displayError, this.l('PasswordReset'), { isHtml: true });
                }
            });
    }
}
