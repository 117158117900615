<div [@routerTransition]>
    <div [class]="containerClass" *ngIf="!loading">
        <div class="row">
            <div class="col-md-12" style="z-index: 0;">
                <kendo-stepper *ngIf="useStepper" [steps]="steps"
                               stepType="full"
                               [(currentStep)]="current"
                               [linear]="false"
                               [style.width.px]="570"
                               (currentStepChange)="currentStepChange($event)">
                </kendo-stepper>
                <tabset #dashboardTabs [justified]="false" [ngClass]="{'nav-hide-tab': hideTabs()}">
                    <tab *ngFor="let page of (userDashboard?.pages || []); let i = index" heading="{{page.name}}"
                         [disabled]="tabDisabled" (selectTab)="selectPageTab(page.id, 0)">
                        <gridster [options]="options[i]" [ngClass]="{'no-tab': !moreThanOnePage()}" class="white-background gridster-height">
                        <!--<gridster [options]="options[i]" [ngClass]="{'no-tab': !moreThanOnePage()}" // Issue with the perfectScrollbar causing SLD-3806 issue
                                  [perfectScrollbar]="{wheelPropagation: true}" class="white-background gridster-height">-->
                            <div [ngClass]="{'freeze-ui': page.widgets.length === undefined}" data-text="LOADING..."></div>
                                <gridster-item *ngFor="let widget of page.widgets" [item]="widget.gridInformation">
                                    <ndc-dynamic [ndcDynamicComponent]="widget.component"
                                                 [ndcDynamicInputs]="retrieveInputs(widget.inputs, widget.component)"
                                                 [ndcDynamicOutputs]="widget.outputs"
                                                 (ndcDynamicCreated)="componentCreated($event)">
                                    </ndc-dynamic>
                                </gridster-item>
                        </gridster>
                    </tab>
                </tabset>
            </div>
        </div>
    </div>
</div>
